import request, {role} from "./_request";

export function getClassList(role, schoolId) {
    return request.get(`/api/${role}/class/list`, {"params": {schoolId}});
}

export function getSchoolList() {
    return request.get("/api/school/list");
}

export function getTeacherList(schoolId, isRole) {
    return request.get(`/api/${role}/teacher/list`, {"params": {schoolId, isRole}});
}

export function getUserList(schoolId) {
    return request.get(`/api/${role}/user/list`, {"params": {schoolId}});
}

export function getOssPresignedUrl(fileType) {
    return request.get("/api/oss/presigned_url", {"params": {fileType}});
}

export function putOssFile(url, file) {
    const headers = {"content-type": file.type};
    return fetch(url, {"method": "PUT", "body": file, headers});
}
